*,
*::before,
*::after {
  box-sizing: border-box;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

html {
  font-size: 88.5%;
}
